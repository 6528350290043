.botonArriba {
	width: 60px;
	height: 60px;
	background: #2eab4d;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	border-radius: 50%;
	font-size: 20px;
	position: fixed;
	bottom: 50px;
	right: 5px;
	cursor: pointer;
	border: 4px solid transparent;
}


@media (max-width: 870px) {
	.botonArriba {
		font-size: 15px;
		width: 40px;
		height: 40px;
	}
}