.contenedorSupremoSpinner {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 20vh;
}


.contenedorSpinner {
  display: flex;
  align-items: center;
  height: 50vh;
}

.spinner {
    font-size: 100px;
    color: #2eab4d;
  }
  
  .spinning {
    animation: spinner 1s linear infinite;
  }
  
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }