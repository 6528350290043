.contenedorUsuario {
  margin-top: 100px;
  text-align: center;
}

.LinkGeneradorContraseña {
  padding: 15px;
}


.loginView {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contenedorLogins {
  min-width: 600px;
  margin-top: 80px;
  background: #fff;
  border-radius: 5px;
  padding: 5px 20px 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.contenedorBotonesInicio {
	display: flex;
	justify-content: center;
}

.providerGoogle {
  display: block;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  transition: .3s ease all;
}


.providerGoogle {
  background: rgb(254, 212, 3);
}

.providerGoogle:hover {
  background: rgb(191, 175, 0);
}


.linkContraseñaOlvidada {
	color: #000 !important;
	font-size: 1.2rem;
}

.linkContraseñaOlvidada a {
	color: #000 !important;
	margin-left: 5px;
}

.linkContraseñaOlvidada a:hover {
	color: #02a802 !important;
}

@media (max-width: 769px) {
	.contenedorLogins {
		margin-top: 90px;
	}
}


@media (max-width: 640px) {
	.contenedorLogins {
		min-width: 500px;
	}

	.formulario input, .formulario textarea, .contenedorBotonesInicio button {
		width: 90% !important;
	}
}

@media (max-width: 570px) {
	.contenedorLogins {
		min-width: 400px;
		margin: 30px 20px 0px 20px;
	}

	.LinkGeneradorContraseña, .linkContraseñaOlvidada, .formulario input, .formulario textarea {
		padding: 0px;
		font-size: 14px !important;
	}

	.formulario label {
		font-size: 16px !important;
		margin-bottom: 3px !important;
	}

	.contenedorRecaptcha{
		display: none !important;
	}

	.loginView {
		height: 87vh;
	}
}

@media (max-width: 410px) {
	.contenedorLogins {
		min-width: 85%;
	}

	.LinkGeneradorContraseña, .linkContraseñaOlvidada {
		font-size: 11px !important;
	}

	.formulario input, .formulario textarea, .contenedorBotonesInicio button {
		width: 85% !important;
		padding: 5px !important;
	}
}



/* Estilos de Registro de Usuario */


.contenedor {
	min-height: 100vh;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}


.formulario > div {
	margin-bottom: 15px;
}

.formulario label {
	display: block;
	font-weight: 600;
	font-size: 1.2rem;
	margin-bottom: 5px;
	color: #1f1f1f;
}

.formulario input,
.formulario textarea {
	font-family: 'Open Sans', sans-serif;
	width: 100%;
	border-radius: 5px;
	border: 2px solid #e2e2e2;
	font-size: 18px;
	padding: 10px;
	margin-bottom: 5px;
	color: #1f1f1f;
	text-align: center;
}

.formulario input:focus {
	outline: none;
	border: 2px solid #03a9f4;
}

.formulario .error {
	color: #e92b2d;
	font-weight: 600;
	font-size: .8em;
	margin: 0;
}

.formulario button[type="submit"] {
	display: block;
	background: #16e643;
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;
	border: none;
	cursor: pointer;
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	color: #fff;
	font-size: 16px;
	transition: .3s ease all;
	margin-bottom: -10px;
}

.formulario button[type="submit"]:hover {
  background: #0e9e0e;
}

.formulario .exito {
	color: #02a802;
	font-weight: 600;
	font-size: .8em;
	margin: 15px 0px 0px 0px !important;
}

.formulario input[type="radio"] {
	width: auto;
}

.LinkGeneradorContraseña {
  color: #000 !important;
  font-size: 1.2rem;
}


.LinkGeneradorContraseña:hover {
  color: #02a802 !important;
}

.contenedorRecaptcha {
	margin-bottom: -10px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -15px;
}

/* Estilos exclusivos de ConfiguracionUsuario */

.contenedorConfiguracionUsuario {
	height: 80vh;
    margin-top: 40px;
}

.contenedorConfiguracionLogins {
	width: 100%;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
}

.contenedorConfiguracionLogins  > .formulario {
	padding: 0px 10px 0px 10px;
	min-width: 700px;
	margin: 35px 50px 0px 65px;
	background: #fff;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

@media (max-width: 768px) {
	.contenedorConfiguracionLogins {
    	flex-direction: column;
    	flex-wrap: nowrap;
    	justify-content: center;
	}
	
	.contenedorConfiguracionLogins  > .formulario {
    	min-width: 450px;
    	margin: 40px 10px 0px 10px;
	}
}

@media (max-width: 480px) {
	.contenedorConfiguracionLogins  > .formulario {
    	min-width: 350px;
	}
}

@media (max-width: 370px) {
	.contenedorConfiguracionLogins  > .formulario {
    	min-width: 250px;
	}
}


/* Estilos de ContraseñaOlvidada */

.contenedorContraseñaOlvidada {
	display: flex;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 20vh;
	text-align: center;
	align-items: center;
}

.contenedorContraseñaOlvidada h1 {
	color: #fff;
}

.contenedorContraseñaOlvidada .formulario {
	width: 40%;
}

.labelContraseñaOlvidada {
	color: #fff !important;
}

.linkRegresar {
	float: left;
	background-color: #16e643;
	border-radius: 5px;
	padding: 5px;
	color: #000 !important;
	text-decoration: none;
}

.linkRegresar:hover {
	background-color: #0e9e0e;
	color: #000;
}

@media (max-width: 820px) {
    .contenedorContraseñaOlvidada .formulario {
		width: 60%;
	}
}

@media (max-width: 600px) {
    .contenedorContraseñaOlvidada .formulario {
		width: 80%;
	}
}


/* Estilos de RestablecerContraseña */

.contenedorRestablecerContraseña {
	display: flex;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 20vh;
	text-align: center;
}

.contenedorRestablecerContraseña .formulario {
	width: 40%;
}

@media (max-width: 820px) {
    .contenedorRestablecerContraseña .formulario {
		width: 60%;
	}
}

@media (max-width: 600px) {
    .contenedorRestablecerContraseña .formulario {
		width: 80%;
	}
}